import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiBase } from 'src/app/link-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mother',
  templateUrl: 'mother.component.html',
  styleUrls: ['mother.component.scss'],
})
export class MotherComponent implements OnInit {
  public firstname: string;
  public lastname: string;
  public wish: string;
  constructor(
    private http: HttpClient,
    private api: ApiBase,
    private router: Router
  ) {}
  ngOnInit(): void {}
  async save() {
    let params = {
      Controller: 'MotherSave',
      firstname: this.firstname,
      lastname: this.lastname,
      wishtext: this.wish,
    };

    console.log(params);
    this.http.post(this.api.link, params).subscribe(async (res: any) => {
      await Swal.fire('', 'ลงนาม สำเร็จ', 'success');
      await this.router.navigateByUrl('');
    });
  }
}
