import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-bordtester',
  templateUrl: 'bordtester.component.html',
  styleUrls: ['bordtester.component.scss'],
})
export class BordTesterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
