import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { BordRoutingModule } from './info-routing.module';
import { NgModule } from '@angular/core';
import { BordTesterComponent } from './bordtester/bordtester.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MotherComponent } from './mother/mother.component';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [BordTesterComponent, MotherComponent],
  imports: [
    BordRoutingModule,
    MatSliderModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    FormsModule,
    BrowserModule,
    CommonModule,
  ],
  exports: [BordTesterComponent, MotherComponent],
  providers: [],
  bootstrap: [],
})
export class BordTesterModule {}
