<div class="login-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Login Form</mat-card-title>
            <mat-card-subtitle>please login first</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field>
                <input matInput placeholder="label" value="" placeholder="username">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)='subpage()'>Ok</button>
        </mat-card-actions>
    </mat-card>
</div>