import { BordTesterComponent } from './bordtester/bordtester.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MotherComponent } from './mother/mother.component';

const routes: Routes = [
  {
    path: 'info/bordtester',
    component: BordTesterComponent,
  },
  {
    path:'info/mother',
    component: MotherComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class BordRoutingModule {}
