<div class="container font-cus">
  <div class="d-flex justify-content-center mt-5">
    <img src="./assets/images/formom.jpg" class="img-fluid" alt="...">
  </div>
  <div class="mt-4">
    <div class="card p-4">
      <div class="mb-3 d-flex justify-content-center">
        <label class="form-label f-head ">ข้าพระพุทธเจ้า</label>
      </div>
      <div class="mb-3">
        <label class="form-label">ชื่อ</label>
        <input type="email" class="form-control" [(ngModel)]="firstname"   placeholder="ชื่อพร้อมคำนำหน้า">
      </div>
      <div class="mb-3">
        <label class="form-label">นามสกุล</label>
        <input type="email" class="form-control" [(ngModel)]="lastname"   placeholder="นามสกุล"><!--#ctrl="ngModel"-->
      </div>
      <div class="input-group">
        <span class="input-group-text">ถวายพระพร</span>
        <textarea class="form-control" aria-label="With textarea" [(ngModel)]="wish"></textarea>
    </div>
      <div class="d-grid gap-2 mt-5">
        <button class="btn btn-primary" type="button" (click)="save()">ลงนามถวายพระพร</button>
      </div>
    </div>
    <div class="mt-4">
      <iframe src="https://api.mysafetynetwork.com/pages/get-wish.view.php" frameborder="0" class="frame-w-h"></iframe>
    </div>
  </div>
</div>
